
import Vue from "vue";
import { Skeleton } from "element-ui";
export default Vue.extend({
  name: "rating-viewer-skeleton",
  components: {
    Skeleton
  },
  props: {
    rating: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      expanded: false
    };
  },
  methods: {
    toggleExpansion() {
      this.expanded = !this.expanded;
    }
  },
  computed: {
    faIcon(): string {
      return this.expanded ? "chevron-up" : "chevron-right";
    }
  }
});
